import React, { useEffect, useCallback, useState, useRef } from "react"
import { Popover } from "@headlessui/react"
import debounce from "../Utils/debounce"
import MagnifyingGlass from "../common/Icons/MagnifyingGlass"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getBrandDetailsById } from "../services/ApiServicesv2"

export default function QkSearch({ data, handleChange, values }) {
  const controller = useRef(new AbortController())
  const [query, setQuery] = useState(
    data?.defaultValue ?? values[data?.key] ?? "",
  )
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [focusedIndex, setFocusedIndex] = useState(0)
  const businessId = useSelector((state) => state.login.active_business)
  const ref = useRef(null)
  const suggestionRef = useRef(null)
  const [brandNames, setBrandNames] = useState({}) // State to store brand names

  useEffect(() => {
    const initialSelectedItems =
      [
        ...(values?.key_ingredients?.map(
          (ingredient) => ingredient?.ingredient_id,
        ) || []),
        ...(values?.other_ingredients?.map(
          (ingredient) => ingredient?.ingredient_id,
        ) || []),
        ...(values?.ingredients?.map(
          (ingredient) => ingredient?.ingredient_id,
        ) || []),
      ] ?? []
    setSelectedItems(initialSelectedItems)

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSuggestion(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      controller.current.abort()
    }
  }, [values, data.key])

  const performSearch = useCallback(
    debounce(async (searchTerm) => {
      try {
        const fetchParams = {
          ...data.searchParams,
          query: searchTerm,
          business_id: businessId,
        }
        const response = await data.searchApi(fetchParams)
        if (response) {
          const mappedResults = data.mapper(response)
          setSearchResults(mappedResults)
          setFocusedIndex(0)
        }
      } catch (err) {
        console.error(err)
      }
    }, 400),
    [data, businessId],
  )

  useEffect(() => {
    if (query.length >= 3) {
      performSearch(query)
      setShowSuggestion(true)
    } else {
      setShowSuggestion(false)
    }

    return () => {
      controller.current.abort()
    }
  }, [query, performSearch])

  const handleInputChange = (e) => {
    setQuery(e.target.value)
  }

  const handleSelectChange = (value) => {
    if (!selectedItems.includes(value?.entry?.name)) {
      handleChange(value)
      setSelectedItems([...selectedItems, value?.entry?.id])
      setQuery("")
      setShowSuggestion(false)
    } else {
      toast.warning("Ingredient already selected")
    }
  }

  const flattenResults = () => {
    let flatResults = []
    let indexCounter = 0

    searchResults.forEach((category) => {
      category?.data?.forEach((item) => {
        flatResults.push({ ...item, flatIndex: indexCounter })
        indexCounter++
      })
    })

    return flatResults
  }

  const handleKeyDown = (e) => {
    const flatResults = flattenResults()
    let newIndex = focusedIndex

    if (e.key === "ArrowDown") {
      newIndex = (focusedIndex + 1) % flatResults.length
    } else if (e.key === "ArrowUp") {
      newIndex = (focusedIndex - 1 + flatResults.length) % flatResults.length
    }

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      setFocusedIndex(newIndex)
      e.preventDefault()

      const focusedElement = suggestionRef.current?.querySelector(
        `[data-index="${newIndex}"]`,
      )
      if (focusedElement) {
        focusedElement.scrollIntoView({ block: "nearest" })
      }
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      const selectedEntry = flatResults.find(
        (item) => item.flatIndex === focusedIndex,
      )
      handleSelectChange({
        entry: selectedEntry,
        type: selectedEntry?.categoryType,
      })
      e.preventDefault()
    }
  }

  const placeholder = `${data.title} ${data.required ? "*" : ""}`

  const isItemSelected = (itemId) => {
    return selectedItems.includes(itemId)
  }

  useEffect(() => {
    const fetchBrandNames = async () => {
      const namesToFetch = {}
      for (const entry of searchResults.flatMap((category) => category.data)) {
        if (
          entry.brand_id &&
          !entry.brand_name &&
          !brandNames[entry.brand_id]
        ) {
          namesToFetch[entry.brand_id] = true
        }
      }

      const brandIdsToFetch = Object.keys(namesToFetch)

      if (brandIdsToFetch.length > 0) {
        const names = {}
        await Promise.all(
          brandIdsToFetch.map(async (brandId) => {
            try {
              const brandDetails = await getBrandDetailsById(brandId)
              names[brandId] = brandDetails?.data?.name || brandId
            } catch (error) {
              console.error(
                `Failed to fetch brand details for ID ${brandId}:`,
                error,
              )
              names[brandId] = brandId // Fallback to ID on error
            }
          }),
        )
        setBrandNames((prevNames) => {
          const updatedNames = { ...prevNames, ...names }
          return updatedNames
        })
      }
    }

    if (searchResults.length > 0) {
      fetchBrandNames()
    }
  }, [searchResults, brandNames])

  return (
    <Popover>
      <div
        className={`relative ${
          data.disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        ref={ref}
      >
        <div className="w-full flex items-center rounded-lg border border-bordergray bg-white px-1 shadow-sm focus-within:border-none focus-within:outline-none focus-within:ring-1 focus-within:ring-black sm:text-sm">
          <input
            maxLength={data?.maxLength ?? 30}
            type="text"
            className={`${
              data.disabled ? "cursor-not-allowed opacity-50" : ""
            } border-none focus:outline-none placeholder:text-textgraylight focus:ring-0 focus:ring-black focus:border-none w-full leading-8`}
            value={query}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={data.disabled}
          />
          <div className="text-textgraylight float-right mr-3">
            <MagnifyingGlass className="w-6 h-6" />
          </div>
        </div>
        {showSuggestion && (
          <div
            className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            ref={suggestionRef}
          >
            {searchResults
              ?.filter((v) => v.data.length > 0)
              .map((category, categoryIndex) => (
                <React.Fragment key={categoryIndex}>
                  <p className="px-2 py-1 font-bold bg-slate-300 text-gray-700 mb-1 capitalize">
                    {category.indexName}
                  </p>
                  {category.data.map((entry, entryIndex) => {
                    const isFocused =
                      focusedIndex ===
                      flattenResults().findIndex((item) => item.id === entry.id)
                    const alreadySelected = isItemSelected(entry.id)
                    return (
                      <button
                        key={entry.id}
                        data-index={flattenResults().findIndex(
                          (item) => item.id === entry.id,
                        )}
                        className={`relative cursor-pointer hover:bg-gray-100 select-none py-2 pl-3 pr-9 w-full text-left ${
                          isFocused ? "bg-gray-100" : ""
                        } ${
                          alreadySelected ? "bg-gray-300 text-gray-500" : ""
                        }`}
                        onClick={() =>
                          !alreadySelected &&
                          handleSelectChange({ entry, type: category.type })
                        }
                        onMouseEnter={() =>
                          setFocusedIndex(
                            flattenResults().findIndex(
                              (item) => item.id === entry.id,
                            ),
                          )
                        }
                      >
                        <div className="flex justify-between items-center">
                          <span className="justify-start">{entry.name}</span>
                          <div className="flex items-center">
                            {entry && entry.type === "COMBO_MEALS" && (
                              <div className="px-2 py-1 text-[13px] text-center text-white rounded-2xl bg-orange-500">
                                {"Combo Meals"}
                              </div>
                            )}
                            {category.indexName === "Recipes" && (
                              <span
                                className="bg-yellow-200 text-black rounded-md px-2 py-1 ml-2 text-[13px] flex justify-center items-center"
                                style={{
                                  width: "130px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {entry.brand_name ||
                                  brandNames[entry.brand_id] ||
                                  entry.brand_id}
                              </span>
                            )}
                          </div>
                        </div>
                      </button>
                    )
                  })}
                </React.Fragment>
              ))}
          </div>
        )}
      </div>
      {data.selectedRender && (
        <data.selectedRender
          values={values}
          data={data}
          handleChange={handleChange}
        />
      )}
    </Popover>
  )
}
