export const getIngredientInfo = (ingredient) => {
  return {
    info: ingredient,
    name: `${ingredient.name} ${
      ingredient.proportion ? `(${ingredient.proportion}%)` : ""
    } ${
      ingredient.compound === "Yes" ? `(${ingredient.ingredient ?? ""})` : ""
    }`?.trim(),
  }
}

export const getAdditiveInfo = (additive, country) => {
  if (additive.name === "Others")
    return {
      name: `${additive.name} ${
        additive.proportion ? `(${additive.proportion}%)` : ""
      }`?.trim(),
      //additive.name?.trim(),
      info: { ...additive, isAdditive: true },
    }

  if (["Flavour"].includes(additive.name)) {
    return {
      name: `${additive.name} ${
        additive.ins_number
          ? ` (${additive.type ?? ""}${additive.ins_number ?? ""})`
          : ""
      } (${additive.nature})`?.trim(),
      info: { ...additive, isAdditive: true },
    }
  }

  let name_segment = ""

  if (additive.additive_value_show === "Additive Name") {
    const parts = additive?.ins_number?.split(/(?<=^\S+)\s+/)
    name_segment = parts?.length > 1 ? parts[1] : ""
  } else if (
    additive &&
    additive.additive_value_show === "INS Number" &&
    additive &&
    additive.ins_number
  ) {
    const insNumber = additive?.ins_number || ""
    const number_part = /\s/.test(insNumber)
      ? insNumber.split(/\s+/)[0]
      : insNumber
    name_segment =
      country === "AUS-NZ" || country === "UK"
        ? number_part
        : "INS " + number_part
  } else if (
    additive.additive_value_show === "E Number" &&
    additive.ins_number
  ) {
    const number_part = additive?.ins_number?.split(/\s+/)[0] || ""
    name_segment = number_part
  } else {
    name_segment = additive?.ins_number || ""
  }

  return {
    name: `${additive.name} ${name_segment ? `(${name_segment})` : ""}
    ${additive.proportion ? `(${additive.proportion}%)` : ""}`?.trim(),
    // `${additive.name} ${
    //   additive.ins_number
    //     ? ` (${additive.type ?? ""}${additive.ins_number ?? ""})`
    //     : ""
    // }`?.trim(),
    info: { ...additive, isAdditive: true },
  }
}
