import { FieldType } from "../constants/enum"

const forified = (output) => {
  return {
    name: "",
    description: "",
    fields: [
      {
        title: "Fortified with",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "fortified_with",
        visible: true,
        required: output.fortified === "Yes",
      },
      {
        title: "Quantity (per 100 g/ml)",
        type: FieldType.NUMBER,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "quantity",
        visible: output["fortified"] === "Yes",
        required: output.fortified === "Yes",
      },
      {
        title: "Unit",
        type: FieldType.DROPDOWN,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: ["g", "L", "ml", "kg"],
        key: "unit",
        visible: output["fortified"] === "Yes",
        required: output.fortified === "Yes",
      },
    ],
  }
}

export default forified
