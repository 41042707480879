import { initialMandatoryDeclarations } from "../components/MandatoryDeclarations/MandatoryDeclarations"
import { FieldType } from "../constants/enum"
import { RECIPE_TYPES } from "../Utils/constants"
import {
  IngredientType,
  IngredientUnits,
  MappedResponse,
  QkFormField,
  SearchIngredientsResponse,
} from "./types"

export function mapListToFormOptions(data: {
  key: string
  title: string
  list?: { key: string; value: string }[]
  items?: string[]
  disable?: boolean
  itemsPerRow?: number
  allowColSpan?: boolean
}): { key: string; value: string }[] {
  if (data.list && data.list.length > 0) {
    return data.list
  } else if (data.items && data.items.length > 0) {
    return data.items?.map((v) => ({
      value: v,
      key: v,
    }))
  } else {
    // return a default value if neither list nor items is present
    return []
  }
}

export function parseSizeAndUnit(input: string) {
  const regex = /(\d+)\s*(g|gm|ml)/i
  const match = String(input).match(regex)

  if (match) {
    const size = match[1]
    const unit = match[2]
    return { size, unit }
  } else {
    return { size: "100", unit: "g" }
  }
}

export function mapIngredientUnitsOptions(values?: {
  ingredient_units?: IngredientUnits[]
}): { key: IngredientUnits; value: IngredientUnits }[] {
  const ingredientUnits = values?.ingredient_units ?? ["g", "ml"]
  return ingredientUnits.map((v) => ({
    value: v,
    key: v,
  }))
}

export function mapSearchIngredientsRes(
  response: SearchIngredientsResponse,
  source?: string,
): MappedResponse {
  const mappedResponse: Array<{
    indexName: string
    type: string
    data: Array<{
      id: number
      name: string
      vegNonVegeterian: string
      unit: string
      serving?: {
        unit: string
        size: number
      }
    }>
  }> = []

  function mandatoryDeclarationsData(UserIngredient: any) {
    const data: any = {}
    Object.keys(initialMandatoryDeclarations).map((key) => {
      if (UserIngredient[key]) {
        data[key] = UserIngredient[key]
      }
    })

    return Object.keys(data).length ? data : ""
  }

  function switchResult(ingredientType: IngredientType) {
    switch (ingredientType) {
      case IngredientType.Ingredients:
        return {
          indexName: "Raw/Standard Ingredients",
          type: "FOLSOL_INGREDIENT",
          data: response[ingredientType]?.map(
            (ing: {
              ing_id: number
              name: string
              code?: string
              display_name?: string
              vegNonVegeterian: string
              serving: {
                unit: string
                size: number
                amount?: number | string
              }
              allergens?: any
            }) => {
              return {
                id: ing.ing_id,
                name: "RW - " + ing.code + " - " + ing.name,
                display_name: ing.display_name,
                vegNonVegeterian: ing.vegNonVegeterian,
                unit: ing.serving.unit,
                serving: ing.serving,
                serving_size:
                  ing?.serving?.amount || ing?.serving?.size || "100",
                allergens: ing.allergens,
                type: "FOLSOL_INGREDIENT",
              }
            },
          ),
        }
      case IngredientType.BaseRecipe:
        return {
          indexName: "My Base Recipes",
          type: "BASE_RECIPE",
          data: response[ingredientType]?.map(
            (ing: {
              recipe_id: number
              name: string
              recipe_yield: string
              recipe_yield_unit: string
              vegNonVegeterian: string
              veg_non_vegeterian?: string
              ni_info?: any
              contain?: string[]
              may_contains?: string[]
              allergens?: any
              more_polyols: boolean
              more_polydextrose: boolean
              caffeine_added: boolean
              monosodium_glutamate: boolean
              plant_stanols_esters: boolean
              plant_stanol_amount: any
              non_caloric_sweetener: boolean
              all_sweetners: boolean
              special_declaration: string
            }) => ({
              id: ing.recipe_id,
              name: ing.name,
              unit: ing.recipe_yield_unit,
              serving_size: ing.recipe_yield,
              vegNonVegeterian:
                ing.vegNonVegeterian || ing.veg_non_vegeterian || "",
              contain:
                (ing &&
                  ing.ni_info &&
                  ing.ni_info.allergens &&
                  ing.ni_info.allergens) ||
                [],
              may_contains:
                (ing &&
                  ing.ni_info &&
                  ing.ni_info.allergens &&
                  ing.ni_info.allergens_may_contain_list) ||
                [],
              mandatory_declarations: {
                more_polyols: ing?.more_polyols,
                more_polydextrose: ing?.more_polydextrose,
                caffeine_added: ing?.caffeine_added,
                monosodium_glutamate: ing?.monosodium_glutamate,
                plant_stanols_esters: ing?.plant_stanols_esters,
                plant_stanol_amount: ing?.plant_stanol_amount,
                non_caloric_sweetener: ing?.non_caloric_sweetener,
                all_sweetners: ing?.all_sweetners,
              },
              special_declaration: ing.special_declaration,
              type: "BASE_RECIPE",
            }),
          ),
        }
      case IngredientType.Recipe:
        return {
          indexName: "My Recipes",
          type: "NEW_RECIPE",
          data: response[ingredientType]?.map(
            (ing: {
              recipe_id: number
              name: string
              recipe_yield_unit: string
              vegNonVegeterian: string
              serving_size: string
              veg_non_vegeterian?: string
              ni_info?: any
              contain?: string[]
              may_contains?: string[]
              allergens?: any
              more_polyols: boolean
              more_polydextrose: boolean
              caffeine_added: boolean
              monosodium_glutamate: boolean
              plant_stanols_esters: boolean
              plant_stanol_amount: any
              non_caloric_sweetener: boolean
              all_sweetners: boolean
              special_declaration: string
            }) => ({
              id: ing.recipe_id,
              name: ing.name,
              unit: ing.recipe_yield_unit,
              serving_size: ing?.serving_size,
              vegNonVegeterian:
                ing.vegNonVegeterian || ing.veg_non_vegeterian || "",
              contain:
                (ing &&
                  ing.ni_info &&
                  ing.ni_info.allergens &&
                  ing.ni_info.allergens) ||
                [],
              may_contains:
                (ing &&
                  ing.ni_info &&
                  ing.ni_info.allergens &&
                  ing.ni_info.allergens_may_contain_list) ||
                [],
              mandatory_declarations: {
                more_polyols: ing?.more_polyols,
                more_polydextrose: ing?.more_polydextrose,
                caffeine_added: ing?.caffeine_added,
                monosodium_glutamate: ing?.monosodium_glutamate,
                plant_stanols_esters: ing?.plant_stanols_esters,
                plant_stanol_amount: ing?.plant_stanol_amount,
                non_caloric_sweetener: ing?.non_caloric_sweetener,
                all_sweetners: ing?.all_sweetners,
              },
              special_declaration: ing.special_declaration,
              type: "NEW_RECIPE",
            }),
          ),
        }
      case IngredientType.UserIngredients:
        return {
          indexName: "My Ingredients",
          type: "USER_INGREDIENT",
          data: response[ingredientType]?.map(
            (ing: {
              uing_id: number
              id?: string
              productName: string
              vegNonVegeterian: string
              veg_non_vegeterian?: string
              contain?: string[]
              may_contains?: string[]
              allergens?: any
              otherNutrient?: any
            }) => ({
              id: ing.id ?? ing.uing_id,
              name: ing.productName,
              unit: "",
              vegNonVegeterian:
                ing.vegNonVegeterian || ing.veg_non_vegeterian || "Vegetarian",
              contain: ing.allergens.contains,
              may_contains: ing.allergens.may_contain,
              mandatoryDeclarations: mandatoryDeclarationsData(ing),
              otherNutrient: ing.otherNutrient,
              type: "USER_INGREDIENT",
            }),
          ),
        }
      case IngredientType.LbProducts:
        return {
          indexName: "Packed Ingredients",
          type: "LABELBLIND_DATABASE",
          data: response[ingredientType]?.map(
            (ing: {
              lb_id: number
              code?: string
              details: {
                health_tags?: any
                products_details: {
                  product_name: string
                  display_name?: string
                }
                ingridients_and_additives: {
                  vegetarian_nonvegetarian_vegan: string
                }
                nutrition_information: {
                  per_100_g_100_ml: string
                }
              }
              contains?: any[]
            }) => ({
              id: ing.lb_id,
              name:
                "PF - " +
                ing.code +
                " - " +
                ing.details.products_details.product_name,
              display_name:
                ing.details.products_details.display_name ||
                ing.details.products_details.product_name,
              unit: parseSizeAndUnit(
                ing?.details?.nutrition_information?.per_100_g_100_ml,
              )?.unit,
              serving_size: parseSizeAndUnit(
                ing?.details?.nutrition_information?.per_100_g_100_ml,
              )?.size,
              contains: [
                ing.details.health_tags.image_5_miscellaneous
                  ?.Allergens_Contains,
              ],
              vegNonVegeterian:
                ing.details.ingridients_and_additives
                  .vegetarian_nonvegetarian_vegan,
              type: "LABELBLIND_DATABASE",
            }),
          ),
        }
      default:
        return undefined
    }
  }

  const searchedIngredientsOrder =
    source && source === RECIPE_TYPES.COMBO_MEALS
      ? [
          IngredientType.Recipe,
          IngredientType.BaseRecipe,
          IngredientType.Ingredients,
          IngredientType.UserIngredients,
          IngredientType.LbProducts,
        ]
      : [
          IngredientType.Ingredients,
          IngredientType.Recipe,
          IngredientType.BaseRecipe,
          IngredientType.UserIngredients,
          IngredientType.LbProducts,
        ]

  searchedIngredientsOrder.forEach((ingredientType) => {
    if (response[ingredientType]) {
      const result: any = switchResult(ingredientType as IngredientType)
      if (result) mappedResponse.push(result)
    }
  })

  return mappedResponse
}

export function validateRequiredFields(
  data: { fields: QkFormField[] },
  output: Record<string, any>,
): true | string {
  try {
    const fields = data.fields || []

    for (const field of fields) {
      const message = `${field.title ?? field.key} is a required field`
      // Check visibility condition
      let visible = false

      if (typeof field.visible === "string") {
        visible = eval(field.visible)
      } else {
        visible = field.visible ?? true
      }

      if (field.required === true && visible) {
        if (!output[field.key]) {
          if (field.title === "Ingredient {{ index }}") {
            return "Ingredient  field cannot be empty"
          }
          return message
        }
        // Check for array
        if (Array.isArray(output[field.key])) {
          if (output[field.key].length === 0) {
            return message
          }
          // Check if single key is present or not
          for (const componentData of output[field.key]) {
            let flag = false
            for (const key of Object.keys(componentData)) {
              if (field.key === "mandatory_nutrient") {
                // If the key exists and the value is numerical 0, convert it to string "0"
                if (componentData[key] === 0) {
                  componentData[key] = "0"
                }
                // Now, the condition checks if the key exists and the value is not undefined or null
                if (
                  componentData[key] !== undefined &&
                  componentData[key] !== null
                ) {
                  flag = true
                }
              } else {
                // Existing condition for other keys
                if (componentData[key]) {
                  flag = true
                }
              }
            }
            if (!flag) {
              return message
            }
          }
        }
      }
      //Allowed Special Charcter For Special Keys
      if (
        field.type === FieldType.TEXT ||
        field.type === FieldType.AUTOSUGGEST ||
        field.type === FieldType.LONG_TEXT ||
        field.type === FieldType.SEARCH
      ) {
        if (field.pattern) {
          if (!field.pattern.test(output[field.key]))
            return `${field.title ?? field.key} - Invalid input`
        } else if (
          ![
            "other_information",
            "instructions_of_use",
            "brand_registration_number",
            "address",
            "npop_accreditation_number",
            "ingredient",
            "npop_accredited_certification_agency",
            "pgs_group_description",
            "pgs_unique_id_code",
            "tea_india_license_number",
            "bis_license_number",
            "product_name",
            "ingredient_name",
            "name",
            "food_name",
            "flavour",
            "storage_instructions_text",
            "PFL_RECIPE_SELECTION",
            "ingredient_name",
            "name",
            "ins_number",
            "household_quanity",
          ].includes(field.key) &&
          !/^[a-zA-Z0-9.,() ]*$/m.test(output[field.key])
        ) {
          return `${
            field.title ?? field.key
          } - Special characters other than . and , are not allowed.`
        }
      }
      // Check for component
      if (
        field.type === FieldType.COMPONENT ||
        field.type === FieldType.INPUT_TABLE ||
        field.type === FieldType.MULTI_INPUT
      ) {
        // Loop through the array
        for (const object of output[field.key] ?? []) {
          // Check if no keys are present
          if (Object.keys(object).length === 0) return message
          if (field.component && typeof field.component === "object") {
            const response = validateRequiredFields(
              { fields: [...field.component.fields] },
              object,
            )
            if (typeof response === "string") {
              return `${field.title ?? field.key} - ${response}`
            }
          }
        }
      }
    }

    // Check for allergens
    if (
      output.allergens === "Yes" &&
      fields.find((v) => v.key === "allergens")
    ) {
      const contains = (output.contains ?? []).filter(
        (item: any) => item !== null && item !== undefined,
      )
      const may_contains = (output.may_contains ?? []).filter(
        (item: any) => item !== null && item !== undefined,
      )

      if (contains.length === 0 && may_contains.length === 0) {
        return "Please select atleast one allergen"
      }
    }
    function convertToBaseUnit(quantity: number, unit: string | number) {
      const unitConversions: any = {
        g: 1,
        kg: 1000,
        ml: 1,
        L: 1000,
      }

      return quantity * unitConversions[unit]
    }

    if (
      fields.find((v) => v.key === "serving_size") &&
      fields.find((v) => v.key === "net_quantity")
    ) {
      const serving_size_in_base_unit = convertToBaseUnit(
        parseInt(output.serving_size),
        output.serving_size_unit,
      )
      const net_qantity_in_base_unit = convertToBaseUnit(
        parseInt(output.net_quantity),
        output.net_quantity_unit,
      )

      // console.log(serving_size_in_base_unit, net_qantity_in_base_unit)
      if (serving_size_in_base_unit > net_qantity_in_base_unit) {
        return "Serving size cannot be greater than Net Quantity"
      }
    }

    if (
      output.add_barcode_number === "Yes" &&
      output.barcode_number.length > 0
    ) {
      if (output.barcode_number.length !== 13) {
        return "Barcode number must be 13 Digit Number only."
      }
    }

    return true
  } catch (error) {
    console.log(error)
    return true
  }
}

export function convertToBaseUnit(quantity: number, unit: string | number) {
  const unitConversions: any = {
    g: 1,
    kg: 1000,
    ml: 1,
    L: 1000,
  }

  return quantity * unitConversions[unit]
}

export function roundToTwoDecimalPlaces(number: Number) {
  return number.toFixed(1)
}
