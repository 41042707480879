export const labelMandatoryDeclaration = (labelData) => {
  const declarations = {
    annattto_color: "Contains Annatto Color",
    "10%_or_more_polyols": "Polyols may have laxative effect",
    "10%_or_more_polydextrose": "Polydextrose may have laxative effect",
    added_caffeine: "Contains caffeine",
    isomaltulose: `Contains isomaltulose ${labelData?.["isomaltulose_qty"] ?? 0}
        (kcal) per 100gm or 100ml`,
    trehalose: "Contains Trehalose",
    "10%_or_more_sorbitol_and_sorbitol_syrup":
      "May have laxative effect, cause bloating and diarrhea in\
        children; and reduce calcium absorption in post-menopausal women",
    monosodium_glutamate:
      "This product contains added MONOSODIUM GLUTAMATE. NOT\
        RECOMMENDED FOR INFANTS BELOW 12 MONTHS AND PREGNANT WOMEN.",
    plant_stanol_esters: `Contains Plant Stanol Esters (as Plant Stanols )
        ${labelData?.["plant_stanol_esters_qty"]} gm/100 gm or 100ml. Patients
        on cholesterol lowering medication should use the product under
        medical supervision. May not be nutritionally appropriate for
        pregnant and lactating women and children under the age of five
        years. CONSUMPTION OF MORE THAN 3g. PER DAY, TOTAL OF
        STEROL,STANOLS OR COMBINATION THEREOF SHALL BE AVOIDED.`,
    added_dietary_fibre:
      "Contains Dietary Fibre (Dextrin) ----- (Source of soluble Dietary Fibre)",
  }

  const applicableDeclarations = []
  Object.keys(declarations).forEach((declaration) => {
    if (labelData?.[declaration] === "Yes") {
      applicableDeclarations.push(declarations?.[declaration])
    }
  })

  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("dried dlucose syrup with sulphur dioxide exceeding 40 ppm")
  ) {
    applicableDeclarations.push(
      "DRIED GLUCOSE SYRUP FOR USE IN SUGAR CONFECTIONERY ONLY",
    )
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("this product is a mixture of instant coffee and chicory")
  ) {
    applicableDeclarations.push(
      `Instant Coffee-Chicory mixture made from blends of coffee and chicory. This mixture contains Coffee (${0}%) Percent Chicory (${0}%) Percent`,
    )
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("contains vegetable fat other than cocoa butter")
  ) {
    applicableDeclarations.push(
      "Contains vegetable fat other than cocoa butter",
    )
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes(
        "this product is a milk powder / skimmed milk powder / condensed milk (sweetened and flavoured) that can be reconstituted into liquid milk",
      )
  ) {
    applicableDeclarations.push("NOT TO BE USED FOR INFANTS BELOW SIX MONTHS")
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("cheese is coated/packed in food grade waxes")
  ) {
    applicableDeclarations.push("COATED WAX TO BE REMOVED BEFORE CONSUMPTION")
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes(
        "frozen desert/frozen confection if made with edible vegetable Oil",
      )
  ) {
    applicableDeclarations.push(
      "Frozen Desserts/Frozen Confection is made with Edible Vegetable Oil",
    )
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes(
        "frozen desert/frozen confection if made with edible vegetable fat",
      )
  ) {
    applicableDeclarations.push(
      "Frozen Desserts/Frozen Confection is made with Edible Vegetable Fat",
    )
  }
  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("maida treated with improver or bleaching agents")
  ) {
    applicableDeclarations.push(
      "REFINED WHEAT FLOUR (MAIDA) TREATED WITH IMPROVER/BLEACHING AGENTS, TO BE USED BY BAKERIES ONLY",
    )
  }

  if (
    labelData?.food_name?.toLowerCase() === "refined oil" &&
    labelData?.flavour?.toLowerCase() !== "vanaspati"
  ) {
    if (labelData?.name_of_oil?.trim().length > 0) {
      applicableDeclarations.push(`Refined ${labelData?.name_of_oil} Oil`)
    }
  }

  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes(
        "this vanaspati is made from more than 30 percent of rice bran oil",
      )
  ) {
    applicableDeclarations.push(
      "This package of vanaspati is made from more than 30 per cent Rice bran oil by weight",
    )
  }

  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("fresh fruits coated with wax") &&
    labelData?.category?.category === "4.1.1.2"
  ) {
    applicableDeclarations.push(`COATED WITH WAX ${labelData?.wax} `)
  }

  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("contains vegetable fat other than cocoa butter") &&
    labelData?.food_name === "Chocolate" &&
    labelData?.category?.category === "5.1.3"
  ) {
    applicableDeclarations.push(
      "CONTAINS COCOA BUTTER EQUIVALENT/ VEGETABLE FAT IN ADDITION TO COCOA BUTTER",
    )
  }

  if (
    labelData?.mandatory_declaration
      ?.map((declaration) => declaration?.toLowerCase())
      ?.includes("this oil is a blended oil")
  ) {
    if (labelData.blended_oil && labelData.blended_oil.length > 0) {
      labelData.blended_oil.forEach((oil) => {
        if (oil.name && oil.weight) {
          applicableDeclarations.push(
            `Multi-Sourced Edible Vegetable Oil ${oil.name} (${oil.weight}% by weight)`,
          )
        }
      })
      applicableDeclarations.push("NOT TO BE SOLD LOOSE")
    }
  }

  if (
    labelData?.common_salt_for &&
    labelData?.food_name === "Edible Common Salt" &&
    labelData?.category?.category === "12.1.1"
  ) {
    applicableDeclarations.push(
      `COMMON SALT FOR ${labelData?.common_salt_for?.toUpperCase()}`,
    )
  }
  if (labelData?.my_product_is) {
    applicableDeclarations.push(labelData?.my_product_is)
  }

  return applicableDeclarations
}

export function containsMilk(labelData) {
  const food_names = [
    "Milk",
    "Buttermilk",
    "Flavoured Milk",
    "Dairy Based Cereal Drinks",
    "Yoghurt Drink",
    "Probiotic Drink",
    "Lassi",
    "Whey Based Drinks",
    "Milkshakes",
    "Fermented Milk Drinks",
    "Curd",
    "Yoghurt",
    "Renneted Milk",
    "Evaporated Milk",
    "Condensed Milk",
    "Sweetened Condensed Milk",
    "Milk Protein Concentrate",
    "Khoa",
    "Malai",
    "Milk Cream",
    "Half and Half Cream",
    "Cream",
    "Whipping Cream",
    "Heavy Cream",
    "Dairy Toppings",
    "Sour Cream",
    "Milk Powder",
    "Skimmed Milk Powder",
    "Cream Powder",
    "Casein Powder",
    "Dairy Permeate Powder",
    "Dairy Whitener",
    "Paneer",
    "Cream Cheese",
    "Cheese",
    "Cheddar Cheese",
    "Edam Cheese",
    "Danbo Cheese",
    "Gouda Cheese",
    "Havarti Cheese",
    "Tilsiter Cheese",
    "Camembert Cheese",
    "Brie Cheese",
    "Saint Paulin Cheese",
    "Samsoe Cheese",
    "Ememtal Cheese",
    "Extra Hard Grating Cheese",
    "Mould Ripened Cheese",
    "Swiss Cheese",
    "Whey Cheese",
    "Processed Cheese",
    "Processed Cheese Spread",
    "Pizza Cheese",
    "Flavoured Processed Cheese",
    "Whey Protein Cheese",
    "Ricotta Cheese",
    "Shrikhand",
    "Icecream",
    "Kulfi",
    "Milk Lolly",
    "Flavoured Yoghurt",
    "Frozen Dessert",
    "Peda",
    "Burfee",
    "Rasmalai",
    "Milk Cake",
    "Gulab Jamun",
    "Rasgulla",
    "Rasmalai",
    "Basundi",
    "Rabri",
    "Chocolate Mousse",
    "Butterscotch Pudding",
    "Cham Cham",
    "Rajbhog",
    "Rasbhari",
    "Khirmohan",
    "Whey",
    "Whey Protein Concentrate",
    "Whey Powder",
    "Ghee",
    "Milk Fat",
  ]

  if (
    food_names
      ?.map((text) => text?.toLowerCase())
      .includes(
        labelData?.food_name?.toLowerCase() ||
          (typeof labelData === "string" && labelData?.toLowerCase()),
      )
  ) {
    return true
  }
  return false
}

export function getMDCheckboxes(labelData) {
  let list = []

  if (
    [
      "Evaporated Milk",
      "Condensed Milk",
      "Sweetened Condensed Milk",
      "Milk Protein Concentrate",
      "Khoa",
      "Milk Powder",
      "Skimmed Milk Powder",
      "Cream Powder",
      "Casein Powder",
      "Dairy Permeate Powder",
    ]
      ?.map((name) => name?.toLowerCase())
      .includes(labelData?.food_name?.toLowerCase())
  ) {
    list.push(
      "This product is a milk powder / skimmed milk powder / condensed milk (sweetened and flavoured) that can be reconstituted into liquid milk",
    )
  }

  if (
    [
      "Paneer",
      "Cream Cheese",
      "Cheese",
      "Cheddar Cheese",
      "Edam Cheese",
      "Danbo Cheese",
      "Gouda Cheese",
      "Havarti Cheese",
      "Tilsiter Cheese",
      "Camembert Cheese",
      "Brie Cheese",
      "Saint Paulin Cheese",
      "Samsoe Cheese",
      "Ememtal Cheese",
      "Extra Hard Grating Cheese",
      "Mould Ripened Cheese",
      "Swiss Cheese",
      "Whey Cheese",
      "Processed Cheese",
      "Processed Cheese Spread",
      "Pizza Cheese",
      "Flavoured Processed Cheese",
      "Whey Protein Cheese",
    ]
      .map((name) => name.toLowerCase())
      .includes(labelData?.food_name?.toLowerCase())
  ) {
    list.push("Cheese is coated/packed in food grade waxes")
  }

  if (
    labelData?.food_name?.toLowerCase() === "coffee" &&
    labelData?.flavour?.toLowerCase() === "coffee powder"
  ) {
    list.push("This product is a mixture of coffee and chicory")
    list.push("This product is a mixture of instant coffee and chicory")
  }

  if (
    [
      "Carbonated Drink",
      "Soft drink",
      "Ade",
      "Fruit based soft drink",
      "Ready to drink Tea",
      "Ready to drink Coffee",
    ]
      ?.map((name) => name?.toLowerCase())
      .includes(labelData?.food_name?.toLowerCase())
  ) {
    list.push(
      "This product is a flavour emulsion and flavour paste meant for use in carbonated or non-carbonated beverages",
    )
  }

  if (
    [
      "Squash",
      "Fruit Syrup",
      "Powdered fruit mix",
      "Crush",
      "Synthetic Syrup",
      "Cordial",
      "Barley water",
      "Sharbat (synthetic syrup)",
      "Synthetic syrup for dispensers",
    ]
      .map((name) => name.toLowerCase())
      .includes(labelData?.food_name?.toLowerCase())
  ) {
    list.push("Fruit Squash containing additional sodium or potassium salt")
  }

  if (labelData?.food_name?.toLowerCase() === "dried glucose syrup") {
    list.push("Dried Glucose Syrup with sulphur dioxide exceeding 40 ppm")
  }

  if (labelData?.food_name?.toLowerCase() === "refined wheat (maida)") {
    list.push("Maida treated with improver or bleaching agents")
  }

  if (labelData?.food_name?.toLowerCase() === "chocolate") {
    list.push("Contains vegetable fat other than cocoa butter")
  }

  if (labelData?.category?.category === "4.1.1.2") {
    list.push("Fresh fruits coated with wax")
  }

  if (labelData?.food_name?.toLowerCase() === "vanaspati") {
    list.push(
      "This vanaspati is made from more than 30 percent of Rice bran oil",
    )
  }
  if (
    labelData?.food_name?.toLowerCase() === "refined Oil" &&
    labelData?.flavour?.toLowerCase() === "vanaspati"
  ) {
    list.push(
      "This vanaspati is made from more than 30 percent of Rice bran oil",
    )
  }

  if (labelData?.food_name?.toLowerCase() === "blended Oil") {
    list.push("This oil is a Blended Oil")
  }

  if (
    [
      "Shrikhand",
      "Icecream",
      "Kulfi",
      "Milk Lolly",
      "Flavoured Yoghurt",
      "Frozen Dessert",
      "Peda",
      "Burfee",
      "Rasmalai",
      "Milk Cake",
      "Gulab Jamun",
      "Rasgulla",
      "Rasmalai",
      "Basundi",
      "Rabri",
      "Chocolate Mousse",
      "Butterscotch Pudding",
      "Cham Cham",
      "Rajbhog",
      "Rasbhari",
      "Khirmohan",
    ]
      .map((name) => name?.toLowerCase())
      .includes(labelData?.food_name?.toLowerCase())
  ) {
    list.push(
      "Frozen Desert/Frozen Confection if made with Edible Vegetable Oil",
    )
    list.push(
      "Frozen Desert/Frozen Confection if made with Edible Vegetable Fat",
    )
  }

  return list
}
