import React, { useState, useEffect } from "react"
import { featuresMapping } from "../../constants/features"
import {
  planDuration,
  defaultStarterPlanDetails,
  defaultBusinessPlanDetails,
  defaultEnterprisePlanDetails,
} from "./plansInfo"
import PricingPlanInfo from "./pricingPlanInfo"
import { useHistory } from "react-router-dom"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useSelector } from "react-redux"
import { savePlanIdToLocalStorage } from "../../Utils/helpers"
import { a } from "aws-amplify"

const PricingSummary = ({
  plansApiData,
  loading,
  viewAllPlans,
  fromModal = false,
}) => {
  const history = useHistory()
  const [pricingSectionDetails, setPricingSectionDetails] = useState([])
  const authenticated = useSelector((state) => state.login.authenticated)

  const goToCheckout = (id) => {
    savePlanIdToLocalStorage(id)
    authenticated ? history.push("/contact-us/") : history.push("/contact-us/")
    // history.push(`/checkout/${id}/`)
  }

  const getFeaturesNames = (features) => {
    const featuresList = []
    Object.keys(features).forEach((feature) => {
      if (typeof features[feature] === "boolean" && features[feature]) {
        featuresList.push(feature)
      }
    })
    return featuresList
  }

  const getPlanFeatures = (features, planFeaturesToExempt) => {
    const featuresObject = {}
    Object.keys(features).forEach((feature) => {
      if (!planFeaturesToExempt.includes(feature)) {
        featuresObject[feature] = features[feature] || ""
      }
    })
    return featuresObject
  }

  const getAllPlans = async () => {
    const response = plansApiData
    const starterPlanDetails = response.find(
      (plan) =>
        plan?.name === "Starter Plan" &&
        plan?.business_type === "Menu Labeling",
    )
    const pricingPlan = {
      starterPlan: {
        ...defaultStarterPlanDetails(authenticated),
        border: "border-[#B8CC3F]",
      },
      businessPlan: {
        ...defaultBusinessPlanDetails(authenticated),
        border: "border-[#3F48CC]",
        popularPlan: true,
      },
      enterprisePlan: {
        ...defaultEnterprisePlanDetails(authenticated),
        border: "border-[#DF6F13]",
      },
    }
    pricingPlan.starterPlan.name = starterPlanDetails?.name
    pricingPlan.starterPlan.recipes = `${starterPlanDetails?.max_limit} Recipes`
    pricingPlan.starterPlan.features = JSON.parse(starterPlanDetails?.features)
    pricingPlan.starterPlan.pricing = `${starterPlanDetails?.amount} / mth`
    pricingPlan.starterPlan.duration =
      planDuration?.[starterPlanDetails?.interval]
    pricingPlan.starterPlan.additional = `plus, Rs. ${starterPlanDetails?.set_up_cost} annual Cloud Set-up`
    pricingPlan.starterPlan.id = starterPlanDetails?._id
    pricingPlan.starterPlan.onClick = () =>
      goToCheckout(starterPlanDetails?._id)

    const businessPlanDetails = response.find(
      (plan) =>
        plan?.name === "Business Plan" &&
        plan?.business_type === "Menu Labeling",
    )
    pricingPlan.businessPlan.name = businessPlanDetails?.name
    pricingPlan.businessPlan.recipes = `${businessPlanDetails?.max_limit} Recipes`
    pricingPlan.businessPlan.features = getPlanFeatures(
      JSON.parse(businessPlanDetails?.features),
      getFeaturesNames(pricingPlan.starterPlan.features),
    )
    pricingPlan.businessPlan.pricing = `${businessPlanDetails?.amount} / mth`
    pricingPlan.businessPlan.duration =
      planDuration?.[businessPlanDetails?.interval]
    pricingPlan.businessPlan.additional = `plus, Rs. ${businessPlanDetails?.set_up_cost} annual Cloud Set-up`
    pricingPlan.businessPlan.id = businessPlanDetails?._id
    pricingPlan.businessPlan.onClick = () =>
      goToCheckout(businessPlanDetails?._id)
    pricingPlan.enterprisePlan.onClick = () => history.push("/contact-us/")
    pricingPlan.enterprisePlan.features = getPlanFeatures(
      pricingPlan?.enterprisePlan?.features,
      getFeaturesNames(JSON.parse(businessPlanDetails?.features)),
    )

    setPricingSectionDetails([
      { ...pricingPlan.starterPlan },
      { ...pricingPlan.businessPlan },
      { ...pricingPlan.enterprisePlan },
    ])
  }

  useEffect(() => {
    if (plansApiData) {
      getAllPlans()
    }
  }, [plansApiData])

  return (
    <div>
      <div className="flex md:flex-row flex-col justify-between pt-[20px]">
        {pricingSectionDetails.map((pricingDetails, index) => (
          <div
            className={`pt-8 pb-6 relative flex justify-center border-2 rounded-2xl px-6 ${
              pricingDetails?.border
            } md:w-[33%] w-full md:mb-0 mb-8 ${
              index !== pricingSectionDetails?.length - 1 ? "mr-5" : ""
            } ${pricingDetails?.popularPlan ? "md:mt-0 mt-6" : ""}`}
          >
            {pricingDetails?.popularPlan && (
              <div className="bg-[#3F48CC] absolute top-[-21px] text-xl text-white px-6 py-2 rounded-lg">
                Most popular
              </div>
            )}
            <div className="w-full">
              <PricingPlanInfo pricingData={pricingDetails} />
              <div className="mt-4">
                {(pricingDetails?.name === "Business Plan" ||
                  pricingDetails?.name === "Enterprise Plan") && (
                  <div className="flex mb-3">
                    <AddCircleIcon htmlColor="#70C043" />
                    <div className="ml-2 text-base font-medium text-[#555555]">
                      {pricingDetails?.name === "Business Plan"
                        ? "All benefits of Starter Plan PLUS"
                        : "All benefits of Business Plan PLUS"}
                    </div>
                  </div>
                )}
                {Object.keys(pricingDetails.features).map(
                  (key) =>
                    pricingDetails.features[key] && (
                      <div className="flex mb-3">
                        <CheckCircleIcon htmlColor="#70C043" />
                        <div className="ml-2 text-base font-medium text-[#555555]">
                          {key === "SUPPORT" ? "Support" : featuresMapping[key]}{" "}
                          {typeof pricingDetails.features[key] !==
                            "boolean" && (
                            <span className="text-[#000] font-bold">
                              <span>-</span>
                              <span>{pricingDetails.features[key]}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {viewAllPlans && (
        <div
          className="inline-flex bg-[#FFD700] cursor-pointer rounded-lg mt-7 py-4 rounded-lg px-7 font-bold text-base text-center"
          onClick={viewAllPlans}
        >
          View All Plans
        </div>
      )}
    </div>
  )
}

export default PricingSummary
