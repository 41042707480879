import { label_preview_images } from "../components/LabelPreview/Constants"

export const EMAIL_REGEX =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
export const GST_REGEX =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
export const PAN_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
export const INGREDIENT_NAME_REGEX = /^[ A-Za-z0-9%-&\s\']*$/

export const RECIPE_LOAD_LIMIT = 16
export const RECIPE_DASHBOARD_PAGE_SIZE = 5
export const RECIPE_FETCHING_ERROR =
  "Something went wrong while fetching recipes"
export const INGREDIENTS_FETCHING_ERROR =
  "Something went wrong while fetching ingredients"
export const BASE_RECIPE_FETCHING_ERROR =
  "Something went wrong while fetching base recipes"
export const QRCODE_COLOR_PALETTE = [
  {
    colors: ["#212529", "#F08226", "#526B84"],
    name: "Orange",
  },
  {
    colors: ["#0D4021", "#F0FDF4", "#FF9B53"],
    name: "Green",
  },
  {
    colors: ["#343030", "#0D6EFD", "#38B7B9"],
    name: "Blue",
  },
]
export const validHealthTagsForZomato = [
  "Low Sodium",
  "Low Sugar",
  "Sugar Free",
  "Low Fat",
  "Low Calorie",
  "Source of Fibre",
  "High Fibre",
  "Source of Protein",
  "High Protein",
  "Keto Friendly",
  "Low Carbs",
  "High Iron",
  "Source of Iron",
  "High Calcium",
  "Source of Calcium",
]

export const preperationMethodOptions = [
  "Boiled",
  "Steamed",
  "Pan fried",
  "Deep fried",
  "Baked",
  "Grilled",
  "Tandoori or Roast",
  "Curry",
  "Freshly squeezed or cold pressed",
  "Minimal or no cooking",
]

export const cuisineOptions = [
  { name: "Chinese" },
  { name: "Continental" },
  { name: "Global Fusion" },
  { name: "Italian" },
  { name: "North Indian" },
  { name: "Other Indian" },
  { name: "South Indian" },
  { name: "Thai" },
  { name: "Others" },
]

export const mealCourseOptions = [
  { name: "Beverage" },
  { name: "Dessert" },
  { name: "Main Course" },
  { name: "Starters" },
  { name: "Salads" },
  { name: "Snacks" },
  { name: "Soup" },
  { name: "Others" },
]

export const unitOptions = [{ name: "GM" }, { name: "ML" }]

export const recipeTypeOptions = [
  {
    value: "Vegetarian",
    label: "Vegetarian",
  },
  {
    value: "NonVegetarian",
    label: "Non-Vegetarian",
  },
  {
    value: "Vegan",
    label: "Vegan (No animal products including milk, honey)",
  },
]

export const RECIPE_TYPES = {
  BASE_RECIPE: "BASE_RECIPE",
  NEW_RECIPE: "NEW_RECIPE",
  NEW_INGREDIENT: "NEW_INGREDIENT",
  COMBO_MEALS: "COMBO_MEALS",
}

export const PPF_PLAN = {
  starter: "Starter Plan - PPF",
  business: "Business Plan - PPF",
  introductory: "Introductory Plan - PPF",
}

export const MENU_LABELLING_PLAN = {
  starter: "Starter Plan",
  business: "Business Plan",
  introductory: "Introductory Plan",
}

export const BUSINESS_MODEL = {
  ppf: "PPF Model",
}
export const BLACKLIST_USERS = []

export const SELECTED_PLAN_KEY = "selectedPlan"
export const DEFAULT_PLAN_KEY = "plan_NgTcQJy9dq20iK"

export const vegNonVegLogo = {
  NonVegetarian: label_preview_images.nonVegLogo,
  "Non Vegetarian": label_preview_images.nonVegLogo,
  Vegetarian: label_preview_images.vegLogo,
  Vegan: label_preview_images.veganLogo,
  "Contains Egg": label_preview_images.eggLogo,
}

export const UPLOAD_FILE_TYPES = {
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  bmp: "image/bmp",
  webp: "image/webp",
  svg: "image/svg+xml",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain",
  csv: "text/csv",
  json: "application/json",
  zip: "application/zip",
  rar: "application/vnd.rar",
}

export const MULTI_PACK_LABEL_LIMIT = 10

export const DOMESTIC_LABEL_TYPE = {
  MULTI_PACK: "MULTI_PACK",
}

export const API_RESPONSE_STATUS = {
  success: "success",
  failure: "failure",
}

export const NO_RDA_NUTRIENT_ALL_AGE_GROUP = [
  "carbohydrates",
  "free_sugars",
  "cholesterol",
  "calcium",
  "mufa",
  "pufa",
]

export const NO_RDA_NUTRIENT_AGE_GROUP_WISE = {
  "0-6 months": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP, "iron"],
  "7-12 months": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "1-3 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "4-6 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "7-9 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "10-12 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "13-15 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "16-18 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
  "Above 18 years": [...NO_RDA_NUTRIENT_ALL_AGE_GROUP],
}
