import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import MaterialCard from "./Card"
import DashboardTable from "./Table"
import ApiServices from "../../services/ApiServices"
import { makeStyles } from "@material-ui/core/styles"
import { RECIPE_DASHBOARD_PAGE_SIZE, RECIPE_TYPES } from "../../Utils/constants"
import { dashboardStyles } from "./dashboardStyles"
import { Link, useHistory } from "react-router-dom"
import { UserDashboardAnalyticsMap } from "../../Utils/analyticsEvents"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import {
  timeSince,
  daysSinceEpoch,
  getUnixTimestamp,
} from "../../Utils/dateTime"
import {
  getRecipesv2,
  getAllLabelsByBusinessIdV2,
  getBusinessById,
  getLabelByBusinessId,
  getAllExportsLabelCountByBusinessId,
} from "../../services/ApiServicesv2"
import { BASE_ROUTES } from "../../constants/routes"
import { getStripeCustomer } from "../../services/ApiServices"
import { getPlanName, hasActivePlan } from "../../helpers/helper"
import {
  getFilteredLabels,
  getAllUsersRoleByBusinessId,
} from "../../services/ApiServicesv2"
import LabelMaker from "../../schema/labelMaker"
import { getAccess } from "../../Utils/UserRoles"
import { getSubscritpionDetails } from "../../services/ApiServicesv2"

const useStyles = makeStyles((theme) => dashboardStyles(theme))

// Define a mapping of label names to access permissions For Labels and Recipes Cards
const cardsAccessMap = {
  "Total labels": "ppf_total_labels",
  "Complete labels": "ppf_completed_labels",
  "Incomplete labels": "ppf_incomplete_labels",
  "Balance labels": "ppf_balance_labels",
  "Total recipes": "ml_total_recipes",
  "Active recipes": "ml_active_recipes",
  "Pending recipes": "ml_pending_recipes",
  "Balance recipes": "ml_balance_recipes",
}

const UserDashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const [labels, setLabels] = React.useState([])
  const [completeCount, setCompleteCount] = React.useState(0)
  const [incompleteCount, setIncompleteCount] = React.useState(0)
  const [balanceLablesCount, setBalanceLablesCount] = React.useState(0)
  const [pendingCount, setPendingCount] = React.useState(0)
  const [totalRecipeCount, setTotalRecipeAssigned] = React.useState()
  const [companyName, setCompanyName] = React.useState("")
  const [userProfileDetails, setUserProfileDetails] = React.useState(null)
  const [businessType, setBusinessType] = React.useState("")
  const [stateBusinessId, setStateBusinessId] = React.useState("")
  const [recipeBrands, setRecipeBrands] = React.useState([])
  const [renewalCount, setRenewalCount] = React.useState(0)
  const [labelTotalCount, setLabelTotalCount] = React.useState(0)
  const [recipeTotalCount, setRecipeTotalCount] = React.useState(0)
  const [fromDateFilter, setFromDateFilter] = React.useState(NaN)
  const [toDateFilter, setToDateFilter] = React.useState(NaN)
  const [accountValidity, setAccountValidity] = React.useState(0)
  const [filteredLabels, setFilteredLabels] = React.useState([])
  const [recipeCreatorNames, setRecipeCreatorNames] = React.useState([])
  const [brandOptions, setBrandOptions] = React.useState([])
  const [brandOptionValues, setBrandOptionValues] = React.useState({})
  const [usersRolesDisplay, setUsersRolesDisplay] = React.useState({})
  const [expireAt, setExpiryDate] = React.useState("")
  const [planDetails, setPlanDetails] = useState()
  const [allLabels, setAllLabels] = useState()
  const [totalLabelsAssigned, setTotalLabelsAssigned] = React.useState(0)
  const [pageAccess, setPageAccess] = useState([])
  const [showHomePage, setShowHomePage] = useState(false)
  const [showBusinessName, setShowBusinessName] = useState(false)
  const [showPlanName, setShowPlanName] = useState(false)
  const [showAccountValidity, setShowAccountValidity] = useState(false)
  const [showTotalLabels, setShowTotalLabels] = useState(false)
  const [showLastFiveLabels, setShowLastFiveLabels] = useState(false)
  const [mlTotalCount, setMlTotalCount] = React.useState(0)
  const [mlActiveCount, setMlActiveCount] = React.useState(0)
  const [mlPendingCount, setMlPendingCount] = React.useState(0)
  const [mlBalanceCount, setMlBalanceCount] = React.useState(0)
  const active_business = useSelector((state) => state.login.active_business)
  const permission = useSelector((state) => state.login.role)
  const [exportsLabelCount, setExportsLabelCount] = useState(0)
  const [aiLabelsCount, setAiLabelsCount] = useState(0)

  const userDetails = JSON.parse(window.localStorage.getItem("persist:root"))
  const loginDetails = userDetails && JSON.parse(userDetails?.login)
  const business_details = JSON.parse(localStorage.getItem("Business_Details"))
  const found_business_details = business_details.find(
    (business) => business.id === active_business,
  )

  const business_option = found_business_details?.type

  const user_module = "home"
  const user_roles = permission

  let business
  if (businessType === "Manufacturer") {
    business = "ppf_business"
  } else {
    business = "ml_business"
  }

  useEffect(() => {
    const business_details = JSON.parse(
      localStorage.getItem("Business_Details"),
    )
    const found_business_details = business_details.find(
      (business) => business.id === active_business,
    )
    setBusinessType(found_business_details.type)
  }, [active_business, business, business_option])

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (!isMounted) return

      const accessPermissions = getAccess(user_module, user_roles)
      setPageAccess(accessPermissions)
      if (business === "ppf_business") {
        setShowHomePage(accessPermissions.includes("ppf_home"))
      } else {
        setShowHomePage(accessPermissions.includes("ml_home"))
      }
      if (business === "ppf_business") {
        setShowBusinessName(accessPermissions.includes("ppf_business_name"))
      } else {
        setShowBusinessName(accessPermissions.includes("ml_business_name"))
      }
      if (business === "ppf_business") {
        setShowPlanName(accessPermissions.includes("ppf_plan_name"))
      } else {
        setShowPlanName(accessPermissions.includes("ml_plan_name"))
      }
      if (business === "ppf_business") {
        setShowAccountValidity(
          accessPermissions.includes("ppf_account_validity"),
        )
      } else {
        setShowAccountValidity(
          accessPermissions.includes("ml_account_validity"),
        )
      }
      if (business === "ppf_business") {
        setShowLastFiveLabels(
          accessPermissions.includes("ppf_last_five_labels"),
        )
      } else {
        setShowLastFiveLabels(
          accessPermissions.includes("ml_last_five_recipes"),
        )
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [business])

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (!isMounted) return

      const planDetails = await getStripeCustomer()
      setPlanDetails({
        ...planDetails,
        plan_details: {
          name: getPlanName(planDetails),
        },
      })
      if (planDetails) {
        setTotalLabelsAssigned(planDetails.plan_details?.max_label)
      }
      const labels_details = await getSubscritpionDetails(active_business)
      if (labels_details && labels_details?.data) {
        setTotalLabelsAssigned(labels_details?.data?.invoice_count)
        setMlTotalCount(labels_details?.data?.invoice_count)
      }

      setTotalRecipeAssigned(labels_details?.data?.invoice_count)
      setBalanceLablesCount(labels_details?.data?.invoice_count)

      let days
      const free_trail_expiry = labels_details?.data?.free_trail_expiry
      const subscription_expiry_time = labels_details?.data?.expire_by
      const current_time = getUnixTimestamp()

      if (free_trail_expiry && free_trail_expiry > current_time) {
        days = daysSinceEpoch(free_trail_expiry)
        setAccountValidity(days)
      } else if (
        subscription_expiry_time &&
        subscription_expiry_time > current_time
      ) {
        days = daysSinceEpoch(subscription_expiry_time)
        setAccountValidity(days)
      }

      const subscription_details = await getStripeCustomer(active_business)
      const isUUID = (string) => {
        const regex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        return regex.test(string)
      }

      const current_plan_name =
        (subscription_details?.plan_details?.name
          ? subscription_details?.plan_details?.name.charAt(0).toUpperCase() +
            subscription_details?.plan_details?.name.slice(1)
          : "") ||
        labels_details?.data?.name ||
        ""

      setPlanDetails({
        ...subscription_details?.data,
        plan_details: {
          name: current_plan_name,
        },
      })
    }
    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const labelMakerSchema = LabelMaker({}, {})
  /** Commenting this function, because ti was seeting expiry at and it is unused*/
  // const getBusinessDetails = async () => {
  //   const business_details = JSON.parse(
  //     localStorage.getItem("Business_Details"),
  //   )

  //   const matchingBusiness = business_details.find(
  //     (business) => business.id === loginDetails.active_business,
  //   )

  //   if (!matchingBusiness) {
  //     const businesses = await ApiServices.getBusinessDetails(
  //       loginDetails.active_business,
  //     )
  //   }
  //   if (businesses?.result?.items?.length > 0) {
  //     const expiryDate = businesses?.result?.items[0]?.expiryDate
  //     setExpiryDate(expiryDate)
  //   }
  // }

  const getBusinessUserRoles = async () => {
    const userRolesDetails = await getAllUsersRoleByBusinessId(
      loginDetails?.active_business,
    )
    const userIds = userRolesDetails.map((user) => user.lbUserId)
    const usersDetails = await ApiServices.getBatchUser({ userIds: userIds })
    let usersByRoles = { Admin: [], Manager: [], User: [] }
    userRolesDetails.forEach(function (user) {
      const userName = usersDetails?.result.filter(
        (u) => u.lbUserId === user.lbUserId,
      )[0]?.firstName
      if (userName) {
        user.permission.map((role) => {
          switch (role) {
            case "Admin":
              usersByRoles.Admin.push(userName)
              break
            case "Manager":
              usersByRoles.Manager.push(userName)
              break
            case "User":
              usersByRoles.User.push(userName)
              break
            default:
          }
        })
      }
    })
    setUsersRolesDisplay(usersByRoles)
  }

  const getLabelRenewalCount = async (labels, recipes, businessType) => {
    let count = 0
    let today = new Date()
    if (businessType === "Manufacturer") {
      labels?.map((label) => {
        const createdAtDate = new Date(label?.createdAt)
        const diffTime = Math.abs(createdAtDate - today)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 180) count++
      })
    } else {
      recipes?.map((recipe) => {
        const createdAtDate = new Date(recipe?.createdAt)
        const diffTime = Math.abs(createdAtDate - today)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 180) count++
      })
    }
    setRenewalCount(count)
  }

  const getLabelsFunction = async () => {
    const businessId = active_business

    setBusinessType(found_business_details.type)

    const subscription_details = JSON.parse(
      localStorage.getItem("Subscription_Details"),
    )

    // if (!subscription_details) {
    //   const business_subscription_details = await getStripeCustomer().then(
    //     (value) => setTotalLabelsAssigned(value?.plan_details?.max_label),
    //   )
    // }

    setStateBusinessId(businessId)

    if (businessType || found_business_details.type === "Manufacturer") {
      if (businessType || found_business_details.type === "Manufacturer") {
        const result = await getFilteredLabels(businessId, 5)
        setLabels(result.slice(0, 5))
      }

      if (!found_business_details) {
        business_details = await getBusinessById(businessId)
      }

      setCompanyName(
        found_business_details?.business_name ||
          business_details?.data?.business_name,
      )
    } else {
      if (found_business_details.type === "Restaurant") {
        const result = await getFilteredLabels(businessId, 5)
        setLabels(result.slice(0, 5))
        const postData = {
          from: 0,
          type: `${RECIPE_TYPES.NEW_RECIPE},${RECIPE_TYPES.COMBO_MEALS}`,
          limit: RECIPE_DASHBOARD_PAGE_SIZE,
          businessId,
        }
        const resp = await getRecipesv2(postData)
        let business_details = JSON.parse(
          localStorage.getItem("Business_Details"),
        )

        const found_business_details = business_details.find(
          (business) => business.id === businessId,
        )

        if (!found_business_details) {
          business_details = await getBusinessById(businessId)
        }

        //        setTotalRecipeAssigned(mlBusinessDetails.result.items[0].max_recipe)
        getLabelRenewalCount(
          {},
          resp?.result?.hits?.hits,
          found_business_details.type,
        )
        setCompanyName(found_business_details?.business_name)

        setLabels(resp?.result)

        setIncompleteCount(
          resp?.result?.filter((r) => r.status === "INCOMPLETE")?.length,
        )

        setRecipeTotalCount(resp?.total)

        // setCompleteCount(
        //   resp?.result?.filter((r) => r.status === "ACTIVE")?.length,
        // )

        setPendingCount(
          resp?.result?.filter((r) => r.status === "PENDING")?.length,
        )

        // resp?.result?.map(async (recipe) => {
        //   const tempBrand = await ApiServices.getBrandsDetails(
        //     businessId,
        //     recipe?.brandId,
        //   )
        //   if (tempBrand?.result?.items[0]?.name === "restaurant")
        //     setRecipeBrands((oldRecipeBrands) => [...oldRecipeBrands, "N/A"])
        //   else
        //     setRecipeBrands((oldRecipeBrands) => [
        //       ...oldRecipeBrands,
        //       tempBrand?.result?.items[0]?.name,
        //     ])
        // })
        // resp?.result?.map(async (recipe) => {
        //   if (recipe.createdBy) {
        //     const res = await ApiServices.getUser(recipe?.createdBy)
        //     const recipeCreatorName = res?.result?.items[0]?.name
        //     setRecipeCreatorNames((oldRecipeCreatorNames) => [
        //       ...oldRecipeCreatorNames,
        //       recipeCreatorName,
        //     ])
        //   } else {
        //     setRecipeCreatorNames((oldRecipeCreatorNames) => [
        //       ...oldRecipeCreatorNames,
        //       "Admin",
        //     ])
        //   }
        // })

        // const brands = await ApiServices.getBrandDetails(
        //   loginDetails?.active_business,
        // )

        // const brandNames = brands?.result?.items?.map((brand) => brand?.name)
        // setBrandOptions(brandNames)
        // brands?.result?.items?.map((brand) =>
        //   setBrandOptionValues((prevState) => ({
        //     ...prevState,
        //     [brand.name]: brand.id,
        //   })),
        // )
      }
    }
  }

  const findCount = (result) => {
    let incompleteCount = 0
    let completeCount = 0
    let totalLabelCount = 0
    let recipePendingCount = 0
    let recipeActiveCount = 0
    if (businessType === "Manufacturer") {
      result?.map((r) => {
        if (r?.label_json?.isLabelComplete === false) {
          incompleteCount++
        } else {
          completeCount++
        }
      })
      result?.map((r) => {
        if (r?.title) totalLabelCount++
      })
      //setLabelTotalCount(totalLabelCount)
      setIncompleteCount(incompleteCount)
      setCompleteCount(completeCount)
    } else {
      setRecipeTotalCount(result?.length)
      result?.map((recipe) => {
        if (recipe?.status === "PENDING") recipePendingCount++
        else if (recipe?.status === "INCOMPLETE") {
          incompleteCount++
          recipePendingCount++
        } else if (recipe?.status === "ACTIVE") recipeActiveCount++
      })
      setCompleteCount(recipeActiveCount)
      setIncompleteCount(incompleteCount)
      setPendingCount(recipePendingCount)
    }
  }

  const brandsFilterHandler = (selectedBrands) => {
    if (!isNaN(fromDateFilter) || !isNaN(toDateFilter))
      dateFilterHandler(fromDateFilter, toDateFilter)
    if (selectedBrands?.length === 0) {
      setFilteredLabels([])
      return getLabelsFunction()
    }
    const result = []
    for (let i = 0; i < selectedBrands?.length; i++) {
      const resultForEach = labels?.filter((recipe) => {
        return recipe?.brandId === brandOptionValues[selectedBrands[i]]
      })
      resultForEach?.map((res) => result.push(res))
    }
    getLabelRenewalCount({}, result, found_business_details.type)
    setFilteredLabels(labels)
    findCount(result)
  }

  const categoriesFilterHandler = (selectedCategories) => {
    sendAnalyticsEvent(UserDashboardAnalyticsMap.food_category_filter_event, {
      "Page Name": "User Dashboard",
    })
    if (!isNaN(fromDateFilter) || !isNaN(toDateFilter))
      dateFilterHandler(fromDateFilter, toDateFilter)
    if (selectedCategories?.length === 0) {
      setFilteredLabels([])
      return getLabelsFunction()
    }
    const result = []
    for (let i = 0; i < selectedCategories?.length; i++) {
      const resultForEach = labels?.filter(
        (label) =>
          label?.label_json?.category_info?.level_1 ===
          selectedCategories[i]?.slice(0, 2)?.trim(),
      )
      resultForEach?.map((res) => result.push(res))
    }
    setFilteredLabels(labels)
    findCount(result)
  }

  const getProfileHandler = async () => {
    let businesses = JSON.parse(localStorage.getItem("Business_Details"))

    const found_business_details = businesses.find(
      (business) => business.id === active_business,
    )

    if (!found_business_details) {
      businesses = await getBusinessById(active_business)
    }

    const lbUserId = loginDetails?.lbUserId
    // const result = await ApiServices.getUser(lbUserId)
    const userName = loginDetails?.name
    const userDesignation = loginDetails?.role
    setUserProfileDetails({
      userName,
      userDesignation,
    })

    // if (businesses?.result?.items?.length > 0) {
    //   const date = businesses?.result?.items[0]?.expiryDate
    //   const days = timeSince(new Date(date))
    //   if (days > 0) {
    //     setAccountValidity(days)
    //   }
    // } else {
    //   const date = result?.result?.items[0]?.createdAt
    //   const days = timeSince(new Date(date))
    //   if (days > 0) {
    //     setAccountValidity(days)
    //   }
    // }
  }

  const dateFilterHandler = async (fromValue, toValue) => {
    const businessId = active_business
    let fromDate = new Date(fromValue)
    let toDate = new Date(toValue)
    if (isNaN(fromDate) && !isNaN(fromDateFilter)) fromDate = fromDateFilter
    if (isNaN(toDate) && !isNaN(toDateFilter)) toDate = toDateFilter
    if (found_business_details.type === "Manufacturer") {
      const result = await getAllLabelsByBusinessIdV2(businessId)

      const filteredResult = result?.data?.map((label) => {
        if (!isNaN(toDate) && !isNaN(fromDate)) {
          if (
            new Date(label?.createdAt) > fromDate &&
            new Date(label?.createdAt) < toDate
          )
            return label
          else return {}
        } else if (isNaN(toDate) && !isNaN(fromDate)) {
          if (new Date(label?.createdAt) > fromDate) return label
          else return {}
        } else if (isNaN(fromDate) && !isNaN(toDate)) {
          if (new Date(label?.createdAt) < toDate) return label
          else return {}
        }
      })
      setLabels(filteredResult)
      getLabelRenewalCount(filteredResult, {}, found_business_details.type)
      findCount(filteredResult)
    } else {
      const postData = {
        from: 0,
        index: "user_new_recipes",
        limit: RECIPE_DASHBOARD_PAGE_SIZE,
        businessId,
      }
      const result = await ApiServices.getRecipesPaginated(postData)
      const filteredResult = result?.result?.map((recipe) => {
        if (!isNaN(toDate) && !isNaN(fromDate)) {
          if (
            new Date(recipe?.createdAt) > fromDate &&
            new Date(recipe?.createdAt) < toDate
          )
            return recipe
          else return {}
        } else if (isNaN(toDate) && !isNaN(fromDate)) {
          if (new Date(recipe?.createdAt) > fromDate) return recipe
          else return {}
        } else if (isNaN(fromDate) && !isNaN(toDate)) {
          if (new Date(recipe?.createdAt) < toDate) return recipe
          else return {}
        }
      })
      getLabelRenewalCount({}, filteredResult, found_business_details.type)
      setLabels(filteredResult)
      setIncompleteCount(
        result?.result?.aggregations?.total_incomplete?.doc_count,
      )
      setRecipeTotalCount(filteredResult?.length)
      setCompleteCount(result?.result?.aggregations?.total_active?.doc_count)
      setPendingCount(result?.result?.aggregations?.total_pending?.doc_count)
      filteredResult?.map(async (recipe) => {
        const tempBrand = await ApiServices.getBrandsDetails(
          businessId,
          recipe?.brandId,
        )
        if (tempBrand?.result?.items[0]?.name === "restaurant")
          setRecipeBrands((oldRecipeBrands) => [...oldRecipeBrands, "N/A"])
        else
          setRecipeBrands((oldRecipeBrands) => [
            ...oldRecipeBrands,
            tempBrand?.result?.items[0]?.name,
          ])
      })
    }
  }

  useEffect(async () => {
    // await getBusinessDetails()
    const permission = loginDetails?.role
    if (!permission === "Admin" && !permission === "Manager") {
      if (businessType === "Manufacturer")
        history.push(`/${BASE_ROUTES.PPF}/user-dashboard/`)
      else history.push(`${BASE_ROUTES.MENU_LABEL}/recipe/`)
    }
    await getLabelsFunction()
    await getProfileHandler()
    // await getBusinessUserRoles()
  }, [])

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (!isMounted) return

      const businessId = active_business
      if (businessType === "Manufacturer") {
        const standard_labels = await getAllLabelsByBusinessIdV2(businessId)
        const ai_labels = await getLabelByBusinessId(businessId)
        const exports_labels = await getAllExportsLabelCountByBusinessId(
          businessId,
        )

        if (exports_labels?.status === "success") {
          setExportsLabelCount(exports_labels?.data?.total || 0)
        }

        if (standard_labels?.data?.success === "success") {
          setAllLabels(standard_labels?.data?.length)
        }

        getLabelRenewalCount(standard_labels?.data, {}, businessType)

        let incompleteCount = 0
        let completeCount = 0
        if (ai_labels && ai_labels.data && ai_labels.data.length > 0) {
          setLabelTotalCount(standard_labels?.data?.length)
          setAiLabelsCount(ai_labels?.data?.length || 0)
        } else {
          setLabelTotalCount(standard_labels?.data?.length)
        }
        standard_labels?.data?.map((r) => {
          if (r?.label_json?.isLabelComplete === true) {
            completeCount++
          } else {
            incompleteCount++
          }
        })
        setIncompleteCount(incompleteCount)

        setCompleteCount(completeCount)

        if (planDetails) {
          if (businessType === "Manufacturer" && totalLabelsAssigned) {
            const balanceLabels =
              totalLabelsAssigned -
              (completeCount + incompleteCount + exportsLabelCount)
            const finalBalanceLabels = isNaN(balanceLabels) ? 0 : balanceLabels
            // setLabelTotalCount(totalLabelsAssigned)
            setBalanceLablesCount(finalBalanceLabels)
          }
        } else if (businessType === "Restaurant") {
          const balanceLabels = totalRecipeCount - recipeTotalCount
          const finalBalanceLabels = isNaN(balanceLabels) ? 0 : balanceLabels
          setBalanceLablesCount(finalBalanceLabels)
        }
      } else if (businessType === "Restaurant") {
        const postData = {
          from: 0,
          index: "NEW_RECIPE",
          limit: 999999,
          businessId,
        }

        const all_recipes = await getRecipesv2(postData)
        let NEW_RECIPE_COUNT = 0
        // let OTHER_TYPE = []

        const recipesLength = all_recipes?.result?.length || 0

        for (let i = 0; i < recipesLength; i++) {
          const recipe = all_recipes.result[i]

          if (recipe.type === "NEW_RECIPE") {
            NEW_RECIPE_COUNT++
          }
          // else {
          //   OTHER_TYPE.push(recipe.type)
          // }
        }

        setRecipeTotalCount(NEW_RECIPE_COUNT)

        let incompleteCount = 0
        let recipePendingCount = 0
        let recipeActiveCount = 0

        all_recipes?.result?.forEach((recipe) => {
          switch (recipe && recipe.type === "NEW_RECIPE" && recipe?.status) {
            case "PENDING":
              recipePendingCount++
              break
            case "INCOMPLETE":
              incompleteCount++
            case "ACTIVE":
              recipeActiveCount++
              break
            default:
          }
        })
        const balanceLabels = mlTotalCount - recipePendingCount
        const finalBalanceLabels = isNaN(balanceLabels) ? 0 : balanceLabels

        setMlActiveCount(recipeActiveCount)
        setMlPendingCount(recipePendingCount)
        setMlBalanceCount(finalBalanceLabels)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [
    businessType,
    labelTotalCount,
    planDetails,
    totalLabelsAssigned,
    mlTotalCount,
    mlActiveCount,
    mlPendingCount,
    mlBalanceCount,
  ])

  const materialCardsArray = {
    Manufacturer: [
      {
        labelName: "Total Labels",
        labelCount: totalLabelsAssigned,
        className: classes.totalLabelCount,
      },
      {
        labelName: "Complete Labels",
        labelCount: completeCount || 0,
        className: classes.completeLabelCount,
      },
      {
        labelName: "Incomplete Labels",
        labelCount: incompleteCount,
        className: classes.incompleteLabelCount,
      },
      {
        labelName: "Label Maker",
        labelCount: completeCount + incompleteCount || 0,
        className: classes.completeLabelCount,
      },
      {
        labelName: "Ai Validation",
        labelCount: aiLabelsCount,
        className: classes.completeLabelCount,
      },
      {
        labelName: "Exports Labels",
        labelCount: exportsLabelCount,
        className: classes.completeLabelCount,
      },
      // {
      //   labelName: "Balance labels",
      //   labelCount: balanceLablesCount,
      //   className: classes.completeLabelCount,
      // },
    ],
    Restaurant: [
      {
        labelName: "Total recipes",
        labelCount: mlTotalCount,
        className: classes.totalLabelCount,
      },
      // {
      //   labelName: "Active recipes",
      //   labelCount: mlActiveCount,
      //   className: classes.completeLabelCount,
      // },
      {
        labelName: "Recipes In-Progress", //Pending recipes
        labelCount: mlPendingCount,
        className: classes.incompleteLabelCount,
      },
      {
        labelName: "Balance recipes",
        labelCount: mlBalanceCount,
        className: classes.completeLabelCount,
      },
    ],
  }
  return (
    <div className={classes.mainDivStyles}>
      {showHomePage ? (
        <div>
          <div className={classes.divPadding}>
            <Grid container spacing={4}>
              <Grid item xs={8} md={9}>
                <Typography className={classes.accountSummaryStyles}>
                  {userProfileDetails?.userDesignation}{" "}
                  {userProfileDetails?.userName}
                  {userProfileDetails ? "'s" : ""} Account summary
                </Typography>
              </Grid>{" "}
              {businessType === "Manufacturer" ? (
                <Grid xs={4} md={3}></Grid>
              ) : (
                ""
              )}
              <Grid item xs={7} md={businessType === "Manufacturer" ? 9 : 7}>
                {showBusinessName && (
                  <Typography variant="h6">
                    Business name: {companyName}
                  </Typography>
                )}
                {showPlanName && (
                  <Typography variant="h6">
                    Plan name:{" "}
                    {planDetails?.plan_details.name.charAt(0).toUpperCase() +
                      planDetails?.plan_details.name.slice(1)}
                  </Typography>
                )}
                {/* <Typography variant="h6">Expired on: {expireAt}</Typography> */}
                {showAccountValidity && (
                  <Typography variant="h6">
                    Account validity:{" "}
                    {accountValidity > 0
                      ? `${accountValidity} days left`
                      : "Account Expired"}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={4}>
              {materialCardsArray[business_option]?.map((item, index) => {
                const requiredPermission = cardsAccessMap[item.labelName]
                if (
                  !requiredPermission ||
                  pageAccess.includes(requiredPermission)
                ) {
                  return (
                    <Grid item xs={12} md={4} lg={4} key={index}>
                      <MaterialCard
                        classes={classes}
                        count={1}
                        labelName={item.labelName}
                        labelCount={item.labelCount}
                        className={item.className}
                      />
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>

            {showLastFiveLabels && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <Box mt={3}>
                      <Typography
                        className={classes.typographyStyles}
                        color="white"
                      >
                        {businessType === "Manufacturer"
                          ? "Recent 5 Labels"
                          : "Recent 5 recipes"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Link
                      className={classes.viewAllStyles}
                      onClick={() =>
                        sendAnalyticsEvent(
                          UserDashboardAnalyticsMap.view_all_event,
                          {
                            "Page Name": "User Dashboard",
                          },
                        )
                      }
                      to={
                        businessType === "Manufacturer"
                          ? `${BASE_ROUTES.PPF}/label-maker`
                          : `${BASE_ROUTES.MENU_LABEL}/recipe`
                      }
                    >
                      <Button
                        color="inherit"
                        className={classes.viewAllButtonStyles}
                      >
                        <span className={classes.navbarMobileLinkStyles}>
                          View All
                        </span>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <DashboardTable
                      businessId={stateBusinessId}
                      recipeCreatorNames={recipeCreatorNames}
                      businessType={businessType}
                      recipeBrands={recipeBrands}
                      labels={
                        filteredLabels?.length > 0 ? filteredLabels : labels
                      }
                      classes={classes}
                    ></DashboardTable>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="h-screen flex items-center justify-center">
          <div className="text-base ml-1 text-[#aaa]">
            You do not have access to this page
          </div>
        </div>
      )}
    </div>
  )
}

export default UserDashboard
