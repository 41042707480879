import React, { useState } from "react"
import { DustbinIcon } from "../assets/icons/Icons"
import QkFieldInput from "./QkFieldInput"

export default function QkInputTable({
  data,
  values,
  handleChangeParent,
  repeatable,
  draggable,
}) {
  const [elements, setElements] = useState(values ? values.length : 1)

  const handleChange = (value, key, index) => {
    if (repeatable) {
      let newArr = values ?? Array(elements).fill({})
      newArr[index] = {
        ...newArr[index],
        [key]: value,
      }
      handleChangeParent(newArr)
    } else {
      if (values)
        handleChangeParent([
          {
            ...values[index],
            [key]: value,
          },
        ])
      else
        handleChangeParent([
          {
            [key]: value,
          },
        ])
    }
  }

  const deleteComponent = (index) => {
    setElements((prev) => prev - 1)
    let newArr = values
    newArr.splice(index, 1)
    handleChangeParent(newArr)
  }

  return (
    <div className="font-bold font-slate-800 relative border-2 sm:rounded-lg bg-slate-200 text-grey-500">
      <table className="w-full text-md text-left overflow-visible">
        <tbody>
          {values?.map((value, index) => {
            return (
              <tr className="bg-white border-b even:bg-gray-100" key={index}>
                <th scope="row" className={`px-6 font-bold text-base`}>
                  {value.ingredient_name}
                  {(!value.ingredient_type ||
                    value.ingredient_type.length === 0) && (
                    <div className="text-red-500 font-semibold pb-[2px] max-w-[265px] leading-4 text-[13px] italic mt-1">
                      {`Ingredient not loaded successfully. Please delete and re-add it.`}
                    </div>
                  )}
                </th>
                {data?.fields.map((field) => {
                  return (
                    <td className="px-2" key={field.key}>
                      <QkFieldInput
                        key={field.key}
                        data={field}
                        values={value}
                        handleChange={(d) => handleChange(d, field.key, index)}
                      />
                    </td>
                  )
                })}
                <td className="px-2">
                  <button className="" onClick={() => deleteComponent(index)}>
                    <DustbinIcon className=" w-4 h-4 " />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
