import React, { useState } from "react"
import { FaBars, FaTimes, FaFacebookF, FaInstagram } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { toast } from "react-toastify"
import logoImage from "../../assets/images/folsol-final-337x180.jpg"
import popupImage from "../../assets/images/popup-image-368x196.jpg"
import packedFoodImage from "../../assets/images/packed-food-label-368x345.png"
import ecommerceImage from "../../assets/images/food-ecommerce-labelling-368x245.png"
import menuLabelImage from "../../assets/images/menu-labelling-368x168.png"
import certifiedIsoImage from "../../assets/images/certified-iso-company-260x254.png"
import fssaiLogo from "../../assets/images/fssai-logo-240x116.png"
import pepsiLogo from "../../assets/images/pepsi-logo-1-240x67.png"
import bikanoLogo from "../../assets/images/bikano-logo-240x188.jpg"
import itcHotelsLogo from "../../assets/images/itc-hotels-logo-240x129.png"
import swiggyLogo from "../../assets/images/swiggy-logo-240x72.png"
import oberoiLogo from "../../assets/images/the-oberoi-group-logo-240x65.png"
import starbucks from "../../assets/images/starbucks-logo-240x128.jpg"
import theobroma from "../../assets/images/theobroma.jpg"
import laptopIcon from "../../assets/images/mbri-laptop.svg"
import settingIcon from "../../assets/images/mbri-setting3.svg"
import custFeedback from "../../assets/images/mbri-cust-feedback.svg"
import likeIcon from "../../assets/images/mbri-like.svg"
import privacyIcon from "../../assets/images/mbri-lock.svg"
import { submitUserLandingPageForm } from "../../services/ApiServicesv2"

const features = [
  {
    title: "Global Exports Labelling",
    description: "Compliant with International Regulations",
    image: popupImage,
  },
  {
    title: "Packed Food Labelling",
    description: "Domestic FSSAI packed food Labelling in 4 Easy Steps",
    image: packedFoodImage,
  },
  {
    title: "E-Commerce Food Labelling",
    description:
      "Label Compliance on 50 parameters of FSSAI and Legal Metrology using AI",
    image: ecommerceImage,
  },
  {
    title: "Menu Labelling",
    description:
      "Digital Menu Label creation for Hotels, Restaurants, QSRs and Catering services",
    image: menuLabelImage,
  },
]

const clientImages = [
  fssaiLogo,
  pepsiLogo,
  bikanoLogo,
  itcHotelsLogo,
  starbucks,
  oberoiLogo,
  theobroma,
  swiggyLogo,
]

const LandingPage = () => {
  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [contact, setContact] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!fullName.trim()) {
      toast.warning("Name is a required field")
      return
    }

    if (!company.trim()) {
      toast.warning("Company Name is a required field")
      return
    }

    const contactPattern = /^\d{10}$/
    if (!contactPattern.test(contact)) {
      toast.warning(
        "Mobile Number must be exactly 10 digits and only contain numbers",
      )
      return
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern.test(email)) {
      toast.warning("Please enter a valid email address")
      return
    }

    if (!message.trim()) {
      toast.warning("Message is a required field")
      return
    }

    const payload = {
      full_name: fullName,
      company: company,
      contact: Number(contact),
      email: email,
      message: message,
    }

    try {
      const response = await submitUserLandingPageForm(payload)
      if (response && response?.data && response?.data?.status === "success") {
        toast.success(
          "Details submitted successfully, our team will be in touch with you",
        )
        setFullName("")
        setContact("")
        setEmail("")
        setCompany("")
        setMessage("")
      }
    } catch (error) {
      toast.error("Failed to submit Details")
    }
  }

  return (
    <>
      <nav className="bg-white shadow-md py-4">
        <div className="mx-auto flex justify-between items-center px-4 md:px-8">
          <a
            href="https://www.foodlabelsolutions.com/"
            className="flex items-center"
          >
            <img src={logoImage} alt="Logo" className="h-12 w-auto" />
          </a>
        </div>
      </nav>

      <section
        id="home"
        className="bg-[#12385b] h-screen flex items-center justify-center text-center px-4"
      >
        <div className="max-w-3xl">
          <h1 className="text-6xl md:text-6xl font-bold mb-4 text-white">
            FoLSol®
          </h1>
          <p className="text-4xl md:text-3xl font-bold mb-6 text-orange-300">
            India’s 1st Digital Regulatory Food Labelling Solution
          </p>
          <a
            href="#contact"
            className="bg-orange-500 text-white px-6 py-3 rounded hover:bg-orange-600"
          >
            Enquire Now
          </a>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className=" text-center px-4 md:px-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#12385b]">
            Comprehensive Regulatory Labelling Software for Food Businesses
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-[#f9fafb] border border-gray-200 rounded-lg shadow-sm flex flex-col md:flex-row items-center p-6"
              >
                <div className="w-full md:w-2/3 md:pr-6">
                  <h3 className="text-xl md:text-2xl font-bold mb-2 text-[#e05524]">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 mb-4 text-sm md:text-base">
                    {feature.description}
                  </p>
                  <a
                    href="#contact"
                    className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 inline-block"
                  >
                    Enquire Now
                  </a>
                </div>
                <div className="w-full md:w-1/3 mt-4 md:mt-0 flex justify-center">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-48 h-auto object-contain"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 bg-[#12385b]">
        <div className="container mx-auto px-4 md:px-8">
          <div className="mb-8">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Benefits
            </h2>
            <p className="text-gray-300 text-lg font-bold">
              Problems we are solving for Food and Beverage Companies
            </p>
          </div>

          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src={laptopIcon}
                  alt="Laptop Icon"
                  className="w-6 h-6 invert"
                />
              </div>
              <p className="text-white text-base md:text-lg font-semibold">
                <span className="font-bold">Digitisation</span> to automate
                processes to manage growing number of SKUs and markets
              </p>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src={settingIcon}
                  alt="Setting Icon"
                  className="w-6 h-6 invert"
                />
              </div>
              <p className="text-white text-base md:text-lg font-semibold">
                <span className="font-bold">
                  Business Process Strengthening
                </span>{" "}
                to ensure compliance and mitigate mislabelling risks
              </p>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src={custFeedback}
                  alt="Feedback Icon"
                  className="w-6 h-6 invert"
                />
              </div>
              <p className="text-white text-base md:text-lg font-semibold">
                <span className="font-bold">100% compliance</span> with ongoing
                regulatory updates
              </p>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src={likeIcon}
                  alt="Like Icon"
                  className="w-6 h-6 invert"
                />
              </div>
              <p className="text-white text-base md:text-lg font-semibold">
                <span className="font-bold">Higher productivity</span> – saves
                time and cost of effort
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src={privacyIcon}
                  alt="Privacy Icon"
                  className="w-6 h-6 invert"
                />
              </div>
              <p className="text-white text-base md:text-lg font-semibold">
                Data Privacy
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-16 bg-white">
        <div className=" mx-auto px-4 md:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="flex justify-center">
            <img
              src={certifiedIsoImage}
              alt="Certified ISO Company"
              className="w-60 h-auto"
            />
          </div>

          <div>
            <h2 className="text-3xl md:text-4xl font-bold text-[#12385b] mb-4">
              About LabelBlind®
            </h2>
            <p className="text-gray-700 text-lg mb-4">
              LabelBlind® (Estd. 2018) is India’s 1st AI-led Digital Food
              Labelling Company.
            </p>
            <p className="text-gray-700 text-lg mb-4">
              Our flagship product, FoLSol® SaaS Solution digitises labelling
              requirements for Packed Food, Fresh Food, Food Ecommerce and Food
              Export Companies. FoLSol® makes food labelling compliance
              efficient for regulatory teams by reducing dependency on manual
              processes and streamlines workflows end to end, from label
              creation to label validation.
            </p>
            <p className="text-gray-700 text-lg mb-6">
              LabelBlind® is an ISO 9001:2015 certified company.
            </p>
            <a
              href="#contact"
              className="bg-orange-500 text-white px-6 py-3 rounded hover:bg-orange-600 inline-block"
            >
              Enquire Now
            </a>
          </div>
        </div>
      </section>

      <section className="py-16 bg-[#f9fafb]">
        <div className=" mx-auto px-4 md:px-8">
          <h2 className="text-3xl md:text-4xl font-bold text-[#12385b] text-center mb-12">
            Our Prestige Clients
          </h2>

          <div className="flex flex-wrap justify-center items-center gap-8">
            {clientImages.map((src, index) => (
              <div
                key={index}
                className="flex justify-center items-center w-36 h-24"
              >
                <img
                  src={src}
                  alt={`Client ${index + 1}`}
                  className="h-auto max-h-24 max-w-full object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 px-6 bg-[#12385b]">
        <div
          id="contact"
          className="max-w-lg mx-auto bg-[#12385b] rounded-lg shadow-md"
        >
          <h2 className="text-3xl font-bold text-white text-center mb-4">
            Get in Touch
          </h2>
          <p className="text-orange-300 text-center text-xl mb-6">
            Our team is happy to answer your questions. Fill out the form below
            or mail us at{" "}
            <a
              href="mailto:support@foodlabelsolutions.com"
              className="underline"
            >
              support@foodlabelsolutions.com
            </a>{" "}
            and we’ll be in touch as soon as possible.
          </p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Name"
              className="w-full p-3 border border-gray-300 rounded"
            />
            <input
              type="text"
              required
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Company Name"
              className="w-full p-3 border border-gray-300 rounded"
            />
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              className="w-full p-3 border border-gray-300 rounded"
            />
            <input
              type="tel"
              required
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Mobile Number"
              className="w-full p-3 border border-gray-300 rounded"
              maxLength="10"
              pattern="[0-9]{10}"
            />
            <textarea
              required
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded h-32"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-[#ff8243] text-white py-3 font-bold text-lg rounded hover:bg-[#f66d27] transition-colors duration-300"
            >
              Send message
            </button>
          </form>
        </div>
      </section>

      <footer className="bg-white text-center py-8">
        <div className="max-w-lg mx-auto px-4">
          <div className="mb-4 flex justify-center">
            <img src={logoImage} alt="FoLSol Logo" className="w-32 h-auto" />
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-gray-700 mb-1">About</h3>
            <p className="text-sm text-gray-600">
              FoLSol® by LabelBlind® is India’s 1st Aggregated, One-Stop Food
              Labelling and Regulatory Compliance Platform.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-gray-700 mb-1">Contact Us</h3>
            <p className="text-sm text-gray-600">
              <a
                href="mailto:support@foodlabelsolutions.com"
                className="hover:underline"
              >
                support@foodlabelsolutions.com
              </a>
            </p>
            <p className="text-sm text-gray-600">+91 9892 722 166</p>
          </div>

          <div>
            <h3 className="font-bold text-gray-700 mb-1">Address</h3>
            <p className="text-sm text-gray-600">Mumbai, India</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default LandingPage
